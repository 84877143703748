
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,500;0,600;0,700;1,400;1,800&display=swap');

* {
    box-sizing: border-box;
}

html {
    margin: 0;
    padding: 0;
    font-size: 10px;
    overflow: hidden;
    overflow-y: auto;
    scroll-behavior: auto !important;
}

body {
    font-size: var(--size-default);
    line-height: var(--lh-default);
    font-family: var(--font-primary);
    color: var(--btc);
    font-weight: var(--regular);
    background-color: var(--body-bg);
    position: relative;
    scroll-behavior: smooth;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    // @media #{$lg-layout} {
    //     overflow: hidden;
    // }
    // @media #{$md-layout} {
    //     overflow: hidden;
    // }
    // @media #{$sm-layout} {
    //     overflow: hidden;
    // }
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
address,
p,
pre,
blockquote,
menu,
ol,
ul,
table,
hr {
    margin: 0;
    margin-bottom: 20px;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    word-break: break-word;
    font-family: var(--font-primary);
}

h1,
.h1 {
    font-size: var(--size-h1);
}

h2,
.h2 {
    font-size: var(--size-h2);
    @media screen and (max-width: 575px){
      font-size: 30px;  
    }
}

h3,
.h3 {
    font-size: var(--size-h3);
}

h4,
.h4 {
    font-size: var(--size-h4);
}

h5,
.h5 {
    font-size: var(--size-h5);
}

h6,
.h6 {
    font-size: var(--size-h6);
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    a {
        color: inherit;
    }
}
table {
    border-collapse: collapse;
    border-spacing: 0;
    margin: 0 0 20px;
    width: 100%;
    @media(max-width:991px){
        overflow-y: scroll;
    }
}

table a,
table a:link,
table a:visited {
    text-decoration: none;
}

cite,
.wp-block-pullquote cite,
.wp-block-pullquote.is-style-solid-color blockquote cite,
.wp-block-quote cite {
    color: var(--primary-color);
}
.font-40{
    font-size: 40px;
    @media screen and (max-width:575px) {
        font-size: 30px;
        line-height: 1.3 !important;
    }
}
.font-small{
    font-size: var(--size-small);
}

